<template>
  <g fill="none" fill-rule="evenodd">
    <path
      :fill="color"
      d="M15 7.995a6.998 6.998 0 0 1-7 6.996c-3.866 0-7-3.132-7-6.996A6.998 6.998 0 0 1 8 1c3.866 0 7 3.132 7 6.995z"
    />
    <path stroke="#6DD400" d="M5 9.994s1 1.999 3 1.999 3-1.999 3-1.999" />
    <path
      fill="#6DD400"
      d="M7 5.997a1 1 0 1 1-2-.002 1 1 0 0 1 2 .002M11 5.997a1 1 0 1 1-2-.002 1 1 0 0 1 2 .002"
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
